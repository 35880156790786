import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Autocomplete from "@mui/material/Autocomplete";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import AxiosInstance from "../config/AxiosInstance";
import Toast from "../Toast";
import { Link } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";

const JoinNow = () => {
  const theme = useTheme();
  let lg = useMediaQuery(theme.breakpoints.up("lg"));
  const [toastOpen, setToastOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [feedback, setFeedback] = useState({
    message: "",
    severinty: "",
  });
  const [inputs, setInputs] = useState({
    image: "",
    image_file: null,
    membership_city: "",
    membership_city_id: null,
    first_name: "",
    middle_name: "",
    last_name: "",
    province: "",
    province_id: null,
    city: "",
    city_id: null,
    barangay: "",
    barangay_id: null,
    street: "",
    zipcode: "",
    business_address: "",
    gender: "",
    birth_date: "",
    TIN: "",
    landline: "",
    mobile_number: "",
    email: "",
    facebook_account: "",
    prc_resolution_number: "",
    prc_series_of: "",
    prc_validity: "",
    name_of_broker: "",
    reb_license_number: "",
    res_license: "",
    valid_prc_license: "",
    rec_prc_license: "",
    prc_license_num: "",
    prc_license_reg_date: "",
    prc_license_val_date: "",
    broker_validity: "",
    college: "",
    course: "",
    graduate_studies: "",
    in_case_name: "",
    in_case_address: "",
    in_case_contact_number: "",
    certification: false,
  });
  const [errors, setErrors] = useState({ ...inputs });
  const [address, setAddress] = useState({
    membershipCities: [
      {
        id: 0,
        label: "Not Found.",
      },
    ],
    membershipCity: null,
    provinces: [
      {
        id: 0,
        label: "Not Found.",
      },
    ],
    province: null,
    cities: [
      {
        id: 0,
        label: "Not Found.",
      },
    ],
    city: null,
    barangays: [
      {
        id: 0,
        label: "Not Found.",
      },
    ],
    barangay: null,
  });

  const [gender, setGender] = useState({
    select: [
      {
        id: 1,
        label: "Male",
      },
      {
        id: 2,
        label: "Female",
      },
    ],
    value: null,
  });

  const [recPRCLicense, setRecPRCLicense] = useState({
    select: [
      {
        id: 1,
        label: "Real Estate Salesperson",
      },
      {
        id: 2,
        label: "Real Estate Broker License",
      },
      {
        id: 3,
        label: "Real Estate Appraiser License",
      },
    ],
    value: null,
  });

  const [resLicense, setResLicense] = useState({
    broker: false,
    appraiser: false,
    salesperson: false,
    license_for_renewal: false,
  });

  const [validPRCLicense, setValidPRCLicense] = useState({
    broker: false,
    appraiser: false,
    salesperson: false,
    license_for_renewal: false,
  });

  const handleChangeCheckBoxX = (e) => {
    setResLicense((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const handleChangeCheckBoxY = (e) => {
    setValidPRCLicense((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const handleChangeRecPRCLicense = (e, v) => {
    setRecPRCLicense((prev) => ({
      ...prev,
      value: v,
    }));

    set("rec_prc_license", v.label);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    const data = JSON.stringify(inputs);
    const isNotValidResLicense = Object.values(resLicense).every(
      (value) => value === false
    );

    const isNotValidPRCLicense = Object.values(validPRCLicense).every(
      (value) => value === false
    );

    if (isNotValidResLicense || isNotValidPRCLicense) {
      setToastOpen(true);
      setFeedback({
        message:
          "PRC Real Estate Licenses requires at least one response per row.",
        severinty: "error",
      });

      return;
    }

    const checkBoxX = JSON.stringify(resLicense);
    const checkBoxY = JSON.stringify(validPRCLicense);
    const formData = new FormData();

    formData.append("image", inputs.image_file);
    formData.append("membership_city", inputs.membership_city);
    formData.append("membership_city_id", inputs.membership_city_id);
    formData.append("first_name", inputs.first_name);
    formData.append("middle_name", inputs.middle_name);
    formData.append("last_name", inputs.last_name);
    formData.append("province", inputs.province);
    formData.append("province_id", inputs.province_id);
    formData.append("city", inputs.city);
    formData.append("city_id", inputs.city_id);
    formData.append("barangay", inputs.barangay);
    formData.append("barangay_id", inputs.barangay_id);
    formData.append("street", inputs.street);
    formData.append("zipcode", inputs.zipcode);
    formData.append("business_address", inputs.business_address);
    formData.append("gender", inputs.gender);
    formData.append("birth_date", inputs.birth_date);
    formData.append("TIN", inputs.TIN);
    formData.append("landline", inputs.landline);
    formData.append("mobile_number", inputs.mobile_number);
    formData.append("email", inputs.email);
    formData.append("facebook_account", inputs.facebook_account);
    formData.append("prc_resolution_number", inputs.prc_resolution_number);
    formData.append("prc_series_of", inputs.prc_series_of);
    formData.append("prc_validity", inputs.prc_validity);
    formData.append("name_of_broker", inputs.name_of_broker);
    formData.append("reb_license_number", inputs.reb_license_number);
    formData.append("broker_validity", inputs.broker_validity);
    formData.append("college", inputs.college);
    formData.append("course", inputs.course);
    formData.append("graduate_studies", inputs.graduate_studies);
    formData.append("in_case_name", inputs.in_case_name);
    formData.append("in_case_address", inputs.in_case_address);
    formData.append("in_case_contact_number", inputs.in_case_contact_number);
    formData.append("res_license", checkBoxX);
    formData.append("valid_prc_license", checkBoxY);
    formData.append("rec_prc_license", inputs.rec_prc_license);
    formData.append("prc_license_num", inputs.prc_license_num);
    formData.append("prc_license_reg_date", inputs.prc_license_reg_date);
    formData.append("prc_license_val_date", inputs.prc_license_val_date);

    if (inputs.certification) {
      if (inputs.image !== "") {
        clearErrors();
        setSubmitting(true);
        AxiosInstance.post("/join", formData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            setToastOpen(true);
            setFeedback({
              message: response.data.message,
              severinty: "success",
            });
            setSubmitting(false);

            setTimeout(() => {
              setSuccess(true);
            }, 1000);
          })
          .catch((error) => {
            setSubmitting(false);
            const validation_errors = error.response.data.validation_errors;
            Object.keys(validation_errors).map((key) => {
              setErrors((prev) => ({
                ...prev,
                [key]: validation_errors[key][0],
              }));
            });
          });
      } else {
        setToastOpen(true);
        setFeedback({
          message: "Please select a photo.",
          severinty: "error",
        });
        setSubmitting(false);
      }
    } else {
      setToastOpen(true);
      setFeedback({
        message: "Please check the certification before submitting.",
        severinty: "error",
      });
      setSubmitting(false);
    }
  };

  const clearErrors = () => {
    Object.keys(errors).map((key) => {
      clearError(key);
    });
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOpen(false);
  };

  const handleChange = (e) => {
    clearError(e.target.name);
    set(e.target.name, e.target.value);
  };

  /**
   * It sets the value of the input field to the value of the selected option.
   */
  const handleChangeCityMembership = (e, v) => {
    clearError("membership_city");
    resetAddressMembershipCity();
    setAddressData("membershipCity", v);

    const id = v.id;
    const name = v.label;

    set("membership_city", name);
    set("membership_city_id", id);
  };

  const handleChangeProvince = async (e, v) => {
    resetAddressCityBarangay();
    clearError("province");
    setAddressData("province", v);

    const id = v.id;
    const name = v.label;

    set("province", name);
    set("province_id", id);

    const response = await AxiosInstance.get("/cities", {
      params: {
        id: id,
        name: name,
      },
    });
    const data = response.data.map((city) => ({
      id: city.id,
      label: city.name,
      zipcode: city.zip_code,
    }));

    const uniqueCities = getUniqueAddressName(data);

    setAddressData("cities", uniqueCities);
  };

  /**
   * It gets the barangays of a city and sets it to the state
   */
  const handleChangeCity = async (e, v) => {
    resetAddressBarangay();
    clearError("city");
    setAddressData("city", v);

    const id = v.id;
    const name = v.label;
    const code = v.zipcode;

    set("city", name);
    set("city_id", id);
    set("zipcode", code);

    const response = await AxiosInstance.get("/barangays", {
      params: {
        id: id,
        name: name,
      },
    });
    const data = response.data.map((city) => ({
      id: city.id,
      label: city.name,
    }));

    const uniqueBarangays = getUniqueAddressName(data);

    setAddressData("barangays", uniqueBarangays);
  };

  const handleChangeBarangay = (e, v) => {
    clearError("barangay");
    setAddressData("barangay", v);

    const id = v.id;
    const name = v.label;

    set("barangay", name);
    set("barangay_id", id);
  };

  const handleChangeGender = (e, v) => {
    clearError("gender");
    setGender((prev) => ({
      ...prev,
      value: v,
    }));

    set("gender", v.label);
  };

  const handleChangeCertification = (e) => {
    set("certification", false);
    set(e.target.name, e.target.checked);
  };

  const handleChangeUploadPhoto = (e) => {
    const fileObj = e.target.files[0];
    const url = URL.createObjectURL(fileObj);

    localStorage.setItem("image", url);

    console.log(url);

    set("image", url);
    set("image_file", fileObj);
  };

  const set = (key, value) => {
    setInputs((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const setAddressData = (key, value) => {
    setAddress((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const resetAddressCityBarangay = () => {
    setAddressData("city", null);
    setAddressData("barangay", null);
    set("city", "");
    set("city_id", null);
    set("barangay", "");
    set("barangay_id", null);
    set("zipcode", "");
  };

  const resetAddressBarangay = () => {
    setAddressData("barangay", null);
    set("barangay", "");
    set("barangay_id", null);
    set("zipcode", "");
  };

  const resetAddressMembershipCity = () => {
    setAddressData("membershipCity", null);
    set("membership_city", "");
    set("membership_city_id", null);
  };

  const clearError = (key) => {
    setErrors((prev) => ({
      ...prev,
      [key]: "",
    }));
  };

  const getUniqueAddressName = (data) => {
    const uniqueNames = [];

    const unique = data.filter((element) => {
      const isDuplicate = uniqueNames.includes(element.label);

      if (!isDuplicate) {
        uniqueNames.push(element.label);

        return true;
      }

      return false;
    });

    return unique;
  };

  useEffect(() => {
    const fetchCities = async () => {
      const response = await AxiosInstance.get("/membership/cities");

      const data = response.data.map((province) => {
        return province.cities.map((city, index) => {
          return {
            id: city.id,
            label: city.name + ", " + province.name,
          };
        });
      });

      let cities = [];

      for (let x = 0; x < data.length; x++) {
        data[x].map((city) => {
          cities.push({
            id: city.id,
            label: city.label,
          });
        });
      }

      const uniqueCities = getUniqueAddressName(cities);

      setAddressData("membershipCities", uniqueCities);
    };

    const fetchProvinces = async () => {
      const response = await AxiosInstance.get("/provinces");
      const data = response.data.map((province) => ({
        id: province.id,
        label: province.name,
      }));

      const uniqueProvinces = getUniqueAddressName(data);

      setAddressData("provinces", uniqueProvinces);
    };

    fetchCities();
    fetchProvinces();
  }, []);

  return (
    <>
      <Header />
      <Toast
        message={feedback.message}
        severinty={feedback.severinty}
        open={toastOpen}
        handleClose={handleCloseToast}
      />
      <Box
        component="main"
        sx={{
          backgroundColor: "gray",
          pt: 5,
          pb: 5,
        }}
      >
        <Container>
          <Paper sx={{ mt: 3, mb: 3 }}>
            {!success && (
              <Box
                sx={{ width: "100%", height: "auto", padding: 5 }}
                component="form"
                onSubmit={handleFormSubmit}
              >
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item lg={9} xs={12} md={12}>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ fontWeight: "bold", color: "primary.main" }}
                    >
                      ACCREDITED REAL ESTATE SALESPERSONS <br /> OF THE
                      PHILIPPINES INC.
                    </Typography>
                    <Typography
                      variant="p"
                      component="div"
                      sx={{ color: "gray" }}
                    >
                      <i>
                        (Only complete and properly accomplished forms shall be
                        accepted for processing. Please read carefully the
                        attached Membership Regulations.)
                        <br></br>
                        Note: Put NA if not applicable.
                      </i>
                    </Typography>
                    <Grid container spacing={2} sx={{ mt: lg ? 10 : 5 }}>
                      <Grid item lg={4} xs={12} md={12}>
                        <Typography variant="h6" component="div">
                          City Membership
                        </Typography>
                      </Grid>
                      <Grid item lg={4} xs={12} md={12}>
                        <FormControl fullWidth error>
                          <Autocomplete
                            options={address.membershipCities}
                            value={address.membershipCity}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="City"
                                variant="filled"
                                name="membership_city"
                                type="text"
                              />
                            )}
                            onChange={(event, value) => {
                              handleChangeCityMembership(event, value);
                            }}
                            isOptionEqualToValue={(option, value) =>
                              value === undefined ||
                              value === "" ||
                              option.id === value.id
                            }
                          />
                          <FormHelperText>
                            {errors.membership_city}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={3} xs={9} md={12}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box
                        sx={{
                          width: 200,
                          height: 200,
                          textAlign: "center",
                          border: "1px solid gray",
                          borderRadius: 1,
                          backgroundImage: `url(${inputs.image})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      >
                        <Typography
                          variant="span"
                          component="div"
                          sx={{ m: 9, color: "gray" }}
                        >
                          2x2 Picture
                        </Typography>
                      </Box>
                    </Box>
                    <FormControl fullWidth error>
                      <TextField
                        name="image"
                        variant="outlined"
                        size="small"
                        type="file"
                        onChange={handleChangeUploadPhoto}
                        sx={{ mt: 3, mb: 1 }}
                      />
                      <FormHelperText>{errors.image}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Divider sx={{ mb: 2 }} />
                <FormLabel component="legend">
                  <Typography
                    variant={lg ? "h5" : "h6"}
                    component="div"
                    sx={{ textAlign: "center", mb: 3 }}
                  >
                    APPLICATION FOR MEMBERSHIP
                  </Typography>
                </FormLabel>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item lg={3} xs={12} md={12}>
                    <Typography variant="h6" component="div">
                      Name of Salesperson
                    </Typography>
                  </Grid>
                  <Grid item lg={3} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <TextField
                        label="Last Name"
                        name="last_name"
                        variant="filled"
                        size="small"
                        type="text"
                        value={inputs.last_name}
                        onChange={handleChange}
                      />
                      <FormHelperText>{errors.last_name}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={3} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <TextField
                        label="First Name"
                        name="first_name"
                        variant="filled"
                        size="small"
                        type="text"
                        value={inputs.first_name}
                        onChange={handleChange}
                      />
                      <FormHelperText>{errors.first_name}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={3} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <TextField
                        label="Middle Name"
                        name="middle_name"
                        variant="filled"
                        size="small"
                        type="text"
                        value={inputs.middle_name}
                        onChange={handleChange}
                      />
                      <FormHelperText>{errors.middle_name}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item lg={3} xs={12} md={12}>
                    <Typography variant="h6" component="div">
                      Home Address
                    </Typography>
                  </Grid>
                  <Grid item lg={3} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <Autocomplete
                        options={address.provinces}
                        value={address.province}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="province"
                            type="text"
                            label="Province"
                            variant="filled"
                          />
                        )}
                        onChange={(event, value) =>
                          handleChangeProvince(event, value)
                        }
                        isOptionEqualToValue={(option, value) =>
                          value === undefined ||
                          value === "" ||
                          option.id === value.id
                        }
                      />
                      <FormHelperText>{errors.province}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={3} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <Autocomplete
                        options={address.cities}
                        value={address.city}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="city"
                            type="text"
                            label="City / Municipality"
                            variant="filled"
                          />
                        )}
                        onChange={(event, value) =>
                          handleChangeCity(event, value)
                        }
                        isOptionEqualToValue={(option, value) =>
                          value === undefined ||
                          value === "" ||
                          option.id === value.id
                        }
                      />
                      <FormHelperText>{errors.city}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={3} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <Autocomplete
                        options={address.barangays}
                        value={address.barangay}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="barangay"
                            type="text"
                            label="Barangay"
                            variant="filled"
                          />
                        )}
                        onChange={(event, value) =>
                          handleChangeBarangay(event, value)
                        }
                        isOptionEqualToValue={(option, value) =>
                          value === undefined ||
                          value === "" ||
                          option.id === value.id
                        }
                      />
                      <FormHelperText>{errors.barangay}</FormHelperText>
                    </FormControl>
                  </Grid>
                  {lg && <Grid item lg={3} xs={12} md={12}></Grid>}
                  <Grid item lg={3} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <TextField
                        label="Street/St./Rd./Blk./Zone"
                        name="street"
                        variant="filled"
                        size="small"
                        type="text"
                        value={inputs.street}
                        onChange={handleChange}
                      />
                      <FormHelperText>{errors.street}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={3} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <TextField
                        disabled
                        label="Zip Code"
                        name="zipcode"
                        variant="filled"
                        size="small"
                        type="text"
                        value={inputs.zipcode}
                        onChange={handleChange}
                      />
                      <FormHelperText>{errors.zipcode}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item lg={3} xs={12} md={12}>
                    <Typography variant="h6" component="div">
                      Business Address
                    </Typography>
                  </Grid>
                  <Grid item lg={9} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <TextField
                        label="Address (Street/St./Rd./Blk./Zone Brgy, City, Province)"
                        name="business_address"
                        variant="filled"
                        size="small"
                        type="text"
                        value={inputs.business_address}
                        onChange={handleChange}
                      />
                      <FormHelperText>{errors.business_address}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  {lg && <Grid item lg={3} xs={12} md={12}></Grid>}
                  <Grid item lg={3} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <Autocomplete
                        options={gender.select}
                        value={gender.value}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="gender"
                            type="text"
                            label="Gender"
                            variant="filled"
                          />
                        )}
                        onChange={(event, value) =>
                          handleChangeGender(event, value)
                        }
                        isOptionEqualToValue={(option, value) =>
                          value === undefined ||
                          value === "" ||
                          option.id === value.id
                        }
                      />
                      <FormHelperText>{errors.gender}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={3} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <TextField
                        label="Birth Date"
                        type="date"
                        name="birth_date"
                        variant="filled"
                        size="large"
                        value={inputs.birth_date}
                        onChange={handleChange}
                      />
                      <FormHelperText>{errors.birth_date}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={3} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <TextField
                        name="TIN"
                        label="TIN"
                        variant="filled"
                        size="large"
                        type="text"
                        value={inputs.TIN}
                        onChange={handleChange}
                      />
                      <FormHelperText>{errors.TIN}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <FormLabel component="legend">
                  <Typography
                    variant={lg ? "h5" : "h6"}
                    component="div"
                    sx={{ textAlign: "center", mb: 3, mt: 5 }}
                  >
                    CONTACT DETAILS
                  </Typography>
                </FormLabel>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item lg={3} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <TextField
                        name="landline"
                        label="Landline Number"
                        variant="filled"
                        size="small"
                        type="text"
                        value={inputs.landline}
                        onChange={handleChange}
                      />
                      <FormHelperText>{errors.landline}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={3} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <TextField
                        name="mobile_number"
                        label="Mobile Number"
                        variant="filled"
                        size="small"
                        type="text"
                        value={inputs.mobile_number}
                        onChange={handleChange}
                      />
                      <FormHelperText>{errors.mobile_number}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={3} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <TextField
                        name="email"
                        label="Email"
                        variant="filled"
                        size="small"
                        type="text"
                        value={inputs.email}
                        onChange={handleChange}
                      />
                      <FormHelperText>{errors.email}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={3} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <TextField
                        name="facebook_account"
                        label="FB Account / https://facebook.com/sample12"
                        variant="filled"
                        size="small"
                        type="text"
                        value={inputs.facebook_account}
                        onChange={handleChange}
                      />
                      <FormHelperText>{errors.facebook_account}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <FormLabel component="legend">
                  <Typography
                    variant={lg ? "h5" : "h6"}
                    component="div"
                    sx={{ textAlign: "center", mb: 3, mt: 5 }}
                  >
                    ACCREDITATION DETAILS
                  </Typography>
                </FormLabel>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item lg={4} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <TextField
                        name="prc_resolution_number"
                        label="PRC Resolution Number e.g. 11, 07, 03, ..."
                        variant="filled"
                        size="small"
                        type="text"
                        value={inputs.prc_resolution_number}
                        onChange={handleChange}
                      />
                      <FormHelperText>
                        {errors.prc_resolution_number}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <TextField
                        name="prc_series_of"
                        label="Series Of"
                        variant="filled"
                        size="small"
                        type="text"
                        value={inputs.prc_series_of}
                        onChange={handleChange}
                      />
                      <FormHelperText>{errors.prc_series_of}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <TextField
                        name="prc_validity"
                        label="Validity"
                        variant="filled"
                        size="small"
                        type="text"
                        value={inputs.prc_validity}
                        onChange={handleChange}
                      />
                      <FormHelperText>{errors.prc_validity}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item lg={4} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <TextField
                        name="name_of_broker"
                        label="Name of Broker"
                        variant="filled"
                        size="small"
                        type="text"
                        value={inputs.name_of_broker}
                        onChange={handleChange}
                      />
                      <FormHelperText>{errors.name_of_broker}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <TextField
                        name="reb_license_number"
                        label="REB License Number"
                        variant="filled"
                        size="small"
                        type="text"
                        value={inputs.reb_license_number}
                        onChange={handleChange}
                      />
                      <FormHelperText>
                        {errors.reb_license_number}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <TextField
                        name="broker_validity"
                        label="Validity"
                        variant="filled"
                        size="small"
                        type="text"
                        value={inputs.broker_validity}
                        onChange={handleChange}
                      />
                      <FormHelperText>{errors.broker_validity}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <FormLabel component="legend">
                  <Typography
                    variant={lg ? "h5" : "h6"}
                    component="div"
                    sx={{ textAlign: "center", mb: 3, mt: 5 }}
                  >
                    PRC REAL ESTATE LICENSES
                  </Typography>
                </FormLabel>
                {lg && (
                  <>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                      <Grid item lg={3} xs={12} md={12}></Grid>
                      <Grid item lg={2} xs={12} md={12}>
                        <Typography variant="h6" component="div">
                          Salesperson
                        </Typography>
                      </Grid>
                      <Grid item lg={2} xs={12} md={12}>
                        <Typography variant="h6" component="div">
                          Broker
                        </Typography>
                      </Grid>
                      <Grid item lg={2} xs={12} md={12}>
                        <Typography variant="h6" component="div">
                          Appraiser
                        </Typography>
                      </Grid>
                      <Grid item lg={2} xs={12} md={12}>
                        <Typography variant="h6" component="div">
                          License for Renewal
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                      <Grid item lg={3} xs={12} md={12}>
                        <Typography variant="h6" component="div">
                          Real Estate License
                        </Typography>
                      </Grid>
                      <Grid item lg={2} xs={12} md={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="broker"
                              checked={resLicense.broker}
                              onChange={handleChangeCheckBoxX}
                            />
                          }
                        />
                      </Grid>
                      <Grid item lg={2} xs={12} md={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="appraiser"
                              checked={resLicense.appraiser}
                              onChange={handleChangeCheckBoxX}
                            />
                          }
                        />
                      </Grid>
                      <Grid item lg={2} xs={12} md={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="salesperson"
                              checked={resLicense.salesperson}
                              onChange={handleChangeCheckBoxX}
                            />
                          }
                        />
                      </Grid>
                      <Grid item lg={2} xs={12} md={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="license_for_renewal"
                              checked={resLicense.license_for_renewal}
                              onChange={handleChangeCheckBoxX}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                      <Grid item lg={3} xs={12} md={12}>
                        <Typography variant="p" component="div">
                          Which PRC License is valid (not expired today)?
                        </Typography>
                      </Grid>
                      <Grid item lg={2} xs={12} md={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="broker"
                              checked={validPRCLicense.broker}
                              onChange={handleChangeCheckBoxY}
                            />
                          }
                        />
                      </Grid>
                      <Grid item lg={2} xs={12} md={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="appraiser"
                              checked={validPRCLicense.appraiser}
                              onChange={handleChangeCheckBoxY}
                            />
                          }
                        />
                      </Grid>
                      <Grid item lg={2} xs={12} md={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="salesperson"
                              checked={validPRCLicense.salesperson}
                              onChange={handleChangeCheckBoxY}
                            />
                          }
                        />
                      </Grid>
                      <Grid item lg={2} xs={12} md={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="license_for_renewal"
                              checked={validPRCLicense.license_for_renewal}
                              onChange={handleChangeCheckBoxY}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                {!lg && (
                  <>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                      <Grid item lg={3} xs={2} md={12}></Grid>
                      <Grid item lg={4} xs={4} md={12}>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{ fontSize: 15 }}
                        >
                          Real Estate License
                        </Typography>
                      </Grid>
                      <Grid item lg={4} xs={6} md={12}>
                        <Typography
                          variant="p"
                          component="div"
                          sx={{ fontSize: 15 }}
                        >
                          Which PRC License is valid (not expired today)?
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                      <Grid item lg={3} xs={2} md={12}>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{ fontSize: 15 }}
                        >
                          Salesperson
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        xs={4}
                        md={12}
                        sx={{ textAlign: "center" }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="salesperson"
                              checked={resLicense.salesperson}
                              onChange={handleChangeCheckBoxX}
                            />
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        xs={6}
                        md={12}
                        sx={{ textAlign: "center" }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="salesperson"
                              checked={validPRCLicense.salesperson}
                              onChange={handleChangeCheckBoxY}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                      <Grid item lg={3} xs={2} md={12}>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{ fontSize: 15 }}
                        >
                          Broker
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        xs={4}
                        md={12}
                        sx={{ textAlign: "center" }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="broker"
                              checked={resLicense.broker}
                              onChange={handleChangeCheckBoxX}
                            />
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        xs={6}
                        md={12}
                        sx={{ textAlign: "center" }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="broker"
                              checked={validPRCLicense.broker}
                              onChange={handleChangeCheckBoxY}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                      <Grid item lg={3} xs={2} md={12}>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{ fontSize: 15 }}
                        >
                          Appraiser
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        xs={4}
                        md={12}
                        sx={{ textAlign: "center" }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="appraiser"
                              checked={resLicense.appraiser}
                              onChange={handleChangeCheckBoxX}
                            />
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        xs={6}
                        md={12}
                        sx={{ textAlign: "center" }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="appraiser"
                              checked={validPRCLicense.appraiser}
                              onChange={handleChangeCheckBoxY}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                      <Grid item lg={3} xs={2} md={12}>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{ fontSize: 15 }}
                        >
                          License for Renewal
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        xs={4}
                        md={12}
                        sx={{ textAlign: "center" }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="license_for_renewal"
                              checked={resLicense.license_for_renewal}
                              onChange={handleChangeCheckBoxX}
                            />
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        xs={6}
                        md={12}
                        sx={{ textAlign: "center" }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="license_for_renewal"
                              checked={validPRCLicense.license_for_renewal}
                              onChange={handleChangeCheckBoxY}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item lg={3} xs={12} md={12}>
                    <Typography variant="p" component="div">
                      Choose <strong>which</strong> PRC Real Estate License for
                      recording
                    </Typography>
                  </Grid>
                  <Grid item lg={3} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <Autocomplete
                        options={recPRCLicense.select}
                        value={recPRCLicense.value}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="rec_prc_license"
                            type="text"
                            label="Choose"
                            variant="filled"
                            size="small"
                          />
                        )}
                        onChange={(event, value) =>
                          handleChangeRecPRCLicense(event, value)
                        }
                        isOptionEqualToValue={(option, value) =>
                          value === undefined ||
                          value === "" ||
                          option.id === value.id
                        }
                      />
                      <FormHelperText>{errors.rec_prc_license}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={3} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <TextField
                        name="prc_license_num"
                        label="PRC License Number"
                        variant="filled"
                        size="small"
                        type="text"
                        onChange={handleChange}
                      />
                      <FormHelperText>{errors.prc_license_num}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item lg={3} xs={12} md={12}>
                    <Typography variant="p" component="div">
                      Registration Date (see PRC ID)
                    </Typography>
                  </Grid>
                  <Grid item lg={3} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <TextField
                        type="date"
                        name="prc_license_reg_date"
                        variant="filled"
                        size="small"
                        onChange={handleChange}
                      />
                      <FormHelperText>
                        {errors.prc_license_reg_date}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item lg={3} xs={12} md={12}>
                    <Typography variant="p" component="div">
                      Valid Until (see PRC ID)
                    </Typography>
                  </Grid>
                  <Grid item lg={3} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <TextField
                        type="date"
                        name="prc_license_val_date"
                        variant="filled"
                        size="small"
                        onChange={handleChange}
                      />
                      <FormHelperText>
                        {errors.prc_license_val_date}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <FormLabel component="legend">
                  <Typography
                    variant={lg ? "h5" : "h6"}
                    component="div"
                    sx={{ textAlign: "center", mb: 3, mt: 5 }}
                  >
                    EDUCATIONAL ATTAINMENT / SPECIAL COURSES
                  </Typography>
                </FormLabel>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item lg={4} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <TextField
                        name="college"
                        label="College / School / University"
                        variant="filled"
                        size="small"
                        type="text"
                        value={inputs.college}
                        onChange={handleChange}
                      />
                      <FormHelperText>{errors.college}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <TextField
                        name="course"
                        label="Course / Bachelor's Degree"
                        variant="filled"
                        size="small"
                        type="text"
                        value={inputs.course}
                        onChange={handleChange}
                      />
                      <FormHelperText>{errors.course}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <TextField
                        name="graduate_studies"
                        label="Graduate Studies / e.g. Master's Degree, PhD"
                        variant="filled"
                        size="small"
                        type="text"
                        value={inputs.graduate_studies}
                        onChange={handleChange}
                      />
                      <FormHelperText>{errors.graduate_studies}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <FormLabel component="legend">
                  <Typography
                    variant={lg ? "h5" : "h6"}
                    component="div"
                    sx={{ textAlign: "center", mb: 3, mt: 5 }}
                  >
                    IN CASE OF EMERGENCY
                  </Typography>
                </FormLabel>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item lg={4} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <TextField
                        name="in_case_name"
                        label="Name"
                        variant="filled"
                        size="small"
                        type="text"
                        value={inputs.in_case_name}
                        onChange={handleChange}
                      />
                      <FormHelperText>{errors.in_case_name}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <TextField
                        name="in_case_address"
                        label="Address"
                        variant="filled"
                        size="small"
                        type="text"
                        value={inputs.in_case_address}
                        onChange={handleChange}
                      />
                      <FormHelperText>{errors.in_case_address}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} xs={12} md={12}>
                    <FormControl fullWidth error>
                      <TextField
                        name="in_case_contact_number"
                        label="Contact Number"
                        variant="filled"
                        size="small"
                        type="text"
                        value={inputs.in_case_contact_number}
                        onChange={handleChange}
                      />
                      <FormHelperText>
                        {errors.in_case_contact_number}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <FormLabel component="legend">
                  <Typography
                    variant={lg ? "h5" : "h6"}
                    component="div"
                    sx={{ textAlign: "center", mb: 3, mt: 5 }}
                  >
                    CERTIFICATION
                  </Typography>
                </FormLabel>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item lg={2} xs={12} md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={inputs.certification}
                          name="certification"
                          onChange={handleChangeCertification}
                        />
                      }
                      label="CERTIFY"
                    />
                  </Grid>
                  <Grid item lg={10} xs={12} md={12}>
                    <Typography variant="p" component="div" sx={{ mt: 1 }}>
                      I hereby certify that the above information are true and
                      correct to the best of knowledge. <br />I hereby certify
                      to the correctness of the foregoing. If agree to abide by
                      the ACRES By Laws, Rules, Regulation and Resolutions.
                    </Typography>
                  </Grid>
                </Grid>
                <FormControl fullWidth>
                  {!submitting && (
                    <Button
                      variant="contained"
                      type="submit"
                      size="large"
                      color="primary"
                      sx={{ mt: 2 }}
                    >
                      SUBMIT
                    </Button>
                  )}
                  {submitting && (
                    <LoadingButton loading variant="contained">
                      SUBMITTING ...
                    </LoadingButton>
                  )}
                </FormControl>
              </Box>
            )}
            {success && (
              <Box sx={{ textAlign: "center", m: lg ? 20 : 5, p: lg ? 5 : 2 }}>
                <Typography
                  variant="h4"
                  component="div"
                  sx={{ color: "primary.main" }}
                >
                  Submitted information will be reviewed by ACRES website
                  administrator.
                </Typography>
                <Typography variant="h5" component="div" sx={{ color: "#000" }}>
                  Your profile will be displayed in the members page after it's
                  approval process. Thank you!
                </Typography>
                <Typography variant="h6" component="div" sx={{ mt: 2 }}>
                  <Link
                    to={"/members"}
                    style={{ textDecoration: "none", color: "primary.man" }}
                  >
                    Continue
                  </Link>
                </Typography>
              </Box>
            )}
          </Paper>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default JoinNow;
