import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import AxiosInstance from "../config/AxiosInstance";
import Toast from "../Toast";
import { Link } from "react-router-dom";
import MemberUserProfileModal from "../MemberUserProfileModal";
import Header from "../Header";
import Footer from "../Footer";

const MemberUserProfile = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [details, setDetails] = useState(null);
  const [resLicense, setResLicense] = useState([]);
  const [validPRCLicense, setValidPRCLicense] = useState([]);
  const theme = useTheme();
  let lg = useMediaQuery(theme.breakpoints.down("lg"));

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const realEstateLicenses = {
    broker: "Broker",
    appraiser: "Appraiser",
    salesperson: "Salesperson",
    license_for_renewal: "License for Renewal",
  };

  let tmpResLicense = null;
  let tmpValidPRCLicense = null;

  useEffect(() => {
    const fetchMemberDetails = async () => {
      const response = await AxiosInstance.get("/member/details", {
        params: {
          email: localStorage.getItem("email"),
        },
      });
      let tmpX = [];
      let tmpY = [];

      setDetails(response.data);

      if (response.data.requirements[0].res_license != "") {
        tmpResLicense = JSON.parse(response.data.requirements[0].res_license);
        Object.keys(realEstateLicenses).map((key) => {
          if (tmpResLicense[key]) {
            tmpX.push(realEstateLicenses[key]);
          }
        });
      }

      if (response.data.requirements[0].valid_prc_license) {
        tmpValidPRCLicense = JSON.parse(
          response.data.requirements[0].valid_prc_license
        );
        Object.keys(realEstateLicenses).map((key) => {
          if (tmpValidPRCLicense[key]) {
            tmpY.push(realEstateLicenses[key]);
          }
        });
      }

      setResLicense(tmpX);
      setValidPRCLicense(tmpY);

      return response;
    };

    fetchMemberDetails();
  }, []);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "gray",
        pt: 5,
      }}
    >
      <Header />
      <Container sx={{ display: "flex", justifyContent: "center" }}>
        <Paper sx={{ mt: 3, mb: 5, width: "100%" }}>
          <Box sx={{ height: details === null ? "600px" : "auto", padding: 5 }}>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item lg={6} xs={12} md={12}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ fontWeight: "bold", color: "primary.main", mb: 3 }}
                >
                  ACCREDITED REAL ESTATE SALESPERSONS <br /> OF THE PHILIPPINES
                  INC.
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12} md={12}>
                {details !== null && (
                  <>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ textAlign: lg ? "left" : "right" }}
                    >
                      <span style={{ color: "gray" }}>Status :</span>{" "}
                      <i>
                        {details.is_verified === 1 ? "Verified" : "Pending"}
                      </i>
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>

            {details !== null && (
              <>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item lg={3} xs={12} md={12}>
                    <Box
                      sx={{
                        width: 200,
                        height: 200,
                        textAlign: "center",
                        border: "1px solid gray",
                        borderRadius: 1,
                        backgroundImage: `url(https://acres.ph/backend/public/uploads/${details.requirements[0].image_path})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        mb: 6,
                      }}
                    >
                      <Typography
                        variant="span"
                        component="div"
                        sx={{ m: 9, color: "gray" }}
                      >
                        2x2 Picture
                      </Typography>
                      <FormControl fullWidth>
                        <Button
                          type="button"
                          variant="outlined"
                          size="small"
                          color="primary"
                          onClick={handleOpenModal}
                          sx={{ mt: 2 }}
                        >
                          Edit Details
                        </Button>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item lg={9} xs={9} md={12}>
                    <Grid container spacing={2}>
                      <Grid item lg={6} xs={12} md={12}>
                        <Typography
                          variant="h5"
                          component="div"
                          sx={{ mb: 3, textTransform: "uppercase" }}
                        >
                          {`${details.firstname} ${details.middlename[0]}. ${details.lastname}`}
                        </Typography>
                        <Typography variant="p" component="div">
                          <span style={{ color: "gray" }}>Gender :</span>{" "}
                          {`${details.gender}`}
                        </Typography>
                        <Typography variant="p" component="div">
                          <span style={{ color: "gray" }}>Birthdate :</span>{" "}
                          {details.birth_date}
                        </Typography>
                        <Typography variant="p" component="div">
                          <span style={{ color: "gray" }}>Mobile number :</span>{" "}
                          {`${details.mobile_number}`}
                        </Typography>
                        <Typography variant="p" component="div">
                          <span style={{ color: "gray" }}>TIN :</span>{" "}
                          {`${details.TIN}`}
                        </Typography>
                      </Grid>
                      <Grid item lg={6} xs={12} md={12}>
                        <Typography variant="p" component="div">
                          <span style={{ color: "gray" }}>
                            Landline number :
                          </span>{" "}
                          {`${details.landline}`}
                        </Typography>
                        <Typography variant="p" component="div">
                          <span style={{ color: "gray" }}>Email :</span>{" "}
                          {`${details.email}`}
                        </Typography>
                        <Typography variant="p" component="div">
                          <span style={{ color: "gray" }}>Facebook :</span>{" "}
                          {`${details.facebook_account}`}
                        </Typography>
                        <Typography variant="p" component="div">
                          <span style={{ color: "gray" }}>Address :</span>{" "}
                          {`${details.member_address[0].street}, ${details.barangay[0].name}, ${details.city[0].name}, ${details.province[0].name}`}
                        </Typography>
                        <Typography variant="p" component="div">
                          <span style={{ color: "gray" }}>
                            Business address :
                          </span>{" "}
                          {`${details.business_address}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider sx={{ mb: 2 }} />
                <FormLabel component="legend">
                  <Typography
                    variant={lg ? "p" : "h5"}
                    component="div"
                    sx={{ textAlign: "left", mb: 3 }}
                  >
                    REAL ESTATE LICENSES DETAILS
                  </Typography>
                </FormLabel>
                <Grid container spacing={2} sx={{ mb: 4, mt: 4 }}>
                  <Grid item lg={6} xs={12} md={12}>
                    <Typography variant="p" component="div">
                      <span style={{ color: "gray" }}>
                        Real Estate Licenses :
                      </span>{" "}
                    </Typography>
                    {resLicense.map(
                      (res) =>
                        res !== "License for Renewal" && (
                          <>
                            <Typography
                              variant="span"
                              component="div"
                              key={res}
                            >
                              {res + " "}
                            </Typography>
                          </>
                        )
                    )}
                  </Grid>
                  <Grid item lg={6} xs={12} md={12}>
                    <Typography variant="p" component="div">
                      <span style={{ color: "gray" }}>
                        Valid PRC License(Not expired) :
                      </span>{" "}
                    </Typography>
                    {validPRCLicense.map(
                      (res) =>
                        res !== "License for Renewal" && (
                          <>
                            <Typography
                              variant="span"
                              component="div"
                              key={res}
                            >
                              {res + " "}
                            </Typography>
                          </>
                        )
                    )}
                  </Grid>
                  <Grid item lg={6} xs={12} md={12}>
                    <Typography variant="p" component="div">
                      <span style={{ color: "gray" }}>
                        PRC Real Estate License :
                      </span>{" "}
                      {`${details.requirements[0].rec_prc_license}`}
                    </Typography>
                    <Typography variant="p" component="div">
                      <span style={{ color: "gray" }}>
                        PRC License number :
                      </span>{" "}
                      {`${details.requirements[0].prc_license_num}`}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} xs={12} md={12}>
                    <Typography variant="p" component="div">
                      <span style={{ color: "gray" }}>Registration Date :</span>{" "}
                      {`${details.requirements[0].prc_license_reg_date}`}
                    </Typography>
                    <Typography variant="p" component="div">
                      <span style={{ color: "gray" }}>Valid Until :</span>{" "}
                      {`${details.requirements[0].prc_license_val_date}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ mb: 2 }} />
                <FormLabel component="legend">
                  <Typography
                    variant={lg ? "p" : "h5"}
                    component="div"
                    sx={{ textAlign: "left", mb: 3 }}
                  >
                    ACCREDITATION DETAILS
                  </Typography>
                </FormLabel>
                <Grid container spacing={2} sx={{ mb: 4, mt: 4 }}>
                  <Grid item lg={6} xs={12} md={12}>
                    <Typography variant="p" component="div">
                      <span style={{ color: "gray" }}>
                        PRC resolution number :
                      </span>{" "}
                      {`${details.requirements[0].prc_resolution_number}`}
                    </Typography>
                    <Typography variant="p" component="div">
                      <span style={{ color: "gray" }}>Series of :</span>{" "}
                      {`${details.requirements[0].prc_series_of}`}
                    </Typography>
                    <Typography variant="p" component="div">
                      <span style={{ color: "gray" }}>Validity :</span>{" "}
                      {`${details.requirements[0].validity_a}`}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} xs={12} md={12}>
                    <Typography variant="p" component="div">
                      <span style={{ color: "gray" }}>Broker name :</span>{" "}
                      {`${details.requirements[0].broker_name}`}
                    </Typography>
                    <Typography variant="p" component="div">
                      <span style={{ color: "gray" }}>
                        REB license number :
                      </span>{" "}
                      {`${details.requirements[0].reb_license_number}`}
                    </Typography>
                    <Typography variant="p" component="div">
                      <span style={{ color: "gray" }}>Validity :</span>{" "}
                      {`${details.requirements[0].validity_b}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ mb: 2 }} />
                <FormLabel component="legend">
                  <Typography
                    variant={lg ? "p" : "h5"}
                    component="div"
                    sx={{ textAlign: "left", mb: 3 }}
                  >
                    EDUCATIONAL ATTAINMENT / SPECIAL COURSES
                  </Typography>
                </FormLabel>
                <Grid container spacing={2} sx={{ mb: 4, mt: 4 }}>
                  <Grid item lg={12} xs={12} md={12}>
                    <Typography variant="p" component="div">
                      <span style={{ color: "gray" }}>
                        College / University :
                      </span>{" "}
                      {`${details.member_educational_attainment[0].college}`}
                    </Typography>
                    <Typography variant="p" component="div">
                      <span style={{ color: "gray" }}>
                        Course / Bachelor's Degree :
                      </span>{" "}
                      {`${details.member_educational_attainment[0].course}`}
                    </Typography>
                    <Typography variant="p" component="div">
                      <span style={{ color: "gray" }}>Graduate Studies :</span>{" "}
                      {`${details.member_educational_attainment[0].graduate_studies}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ mb: 2 }} />
                <FormLabel component="legend">
                  <Typography
                    variant={lg ? "p" : "h5"}
                    component="div"
                    sx={{ textAlign: "left", mb: 3 }}
                  >
                    IN CASE OF EMERGENCY
                  </Typography>
                </FormLabel>
                <Grid container spacing={2} sx={{ mb: 4, mt: 4 }}>
                  <Grid item lg={12} xs={12} md={12}>
                    <Typography variant="p" component="div">
                      <span style={{ color: "gray" }}>Name :</span>{" "}
                      {`${details.member_incase_of_emergency[0].incase_name}`}
                    </Typography>
                    <Typography variant="p" component="div">
                      <span style={{ color: "gray" }}>Address :</span>{" "}
                      {`${details.member_incase_of_emergency[0].incase_address}`}
                    </Typography>
                    <Typography variant="p" component="div">
                      <span style={{ color: "gray" }}>Contact number :</span>{" "}
                      {`${details.member_incase_of_emergency[0].incase_contact_number}`}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Paper>
      </Container>
      {details !== null && (
        <MemberUserProfileModal
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          details={details}
        />
      )}
      <Footer />
    </Box>
  );
};

export default MemberUserProfile;
