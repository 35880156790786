import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import AcresNewsLatestA from "../../imgs/acres-news-latest-1.jpg";
import AcresNewsLatestB from "../../imgs/acres-news-latest-2.jpg";
import AcresNewsLatestC from "../../imgs/acres-news-latest-3.jpg";
import AcresNewsLatestE from "../../imgs/acres-news-latest-4.jpg";
import AcresNewsA from "../../imgs/acres-news1.jpeg";
import AcresNewsB from "../../imgs/acres-news2.png";
import Banner from "../../imgs/members_background.jpg";
import Footer from "../Footer";
import Header from "../Header";

const News = () => {
  const theme = useTheme();
  let lg = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <>
      <Header />
      <Container>
        <Box>
          <Typography
            variant="h3"
            component="div"
            sx={{ textAlign: "center", m: 5, fontWeight: "bold" }}
          >
            News and Updates
          </Typography>
        </Box>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Grid container spacing={2}>
          <Grid item lg={12} xs={12} md={12} sx={{ mt: 5, mb: 5 }}>
            <Typography
              variant="h4"
              component="div"
              sx={{ fontWeight: "bold" }}
            >
              Meet the champion of the voiceless “ahente ng lupa”
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2 }}>
              Southerner May 03, 2023
            </Typography>
            <Box sx={{ my: 4 }}>
              <img
                src={AcresNewsLatestA}
                alt="ACRES News A"
                width={lg ? 500 : 250}
              />
            </Box>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              ACRES’ National President Chris Malazarte during the press
              conference said that the benefits of new and old real estate
              salespersons’ membership with ACRES is their inclusion in the
              ACRES website of accredited and PRC-licensed salespersons branding
              them as legitimate marketers. Secondly, ACRES will help brokers
              settle concerns or issues involving developers such as
              non-delivery of pre-selling projects, denial or underpayment of
              commissions and incentives, abuse, and harassment. Thirdly, ACRES
              gives access to extensive training in skills development,
              professionalism and career development that could widen their
              business opportunities. Lastly, realty firms, on the other hand,
              will have a solid representation in the legislative agenda through
              ACRES for lobbying policies that are beneficial both to brokerage
              firms and its salespeople.
            </Typography>
            <Typography
              variant="p"
              component="div"
              sx={{ mt: 2, mb: 2, color: "primary.main" }}
            >
              THE lowly and voiceless real estate sales persons or popular as
              “ahente ng lupa” has finally found its champion. <br />
              Thanks to ACRES or Accredited Real Estate Salespersons in the
              Philippines, Inc., this advocacy group commits to address concerns
              and issues of the growing “ahente” or salespersons in real estate
              who has been lacking in support to regulatory requirements like
              accreditation and licenses. <br />
              Real estate salespersons comprise the biggest number of
              professionals in the real estate service. ACRES serves as the
              primary voice of thousands of sales agents across the Philippines.
              It aims to widen the real estate salesperson’s opportunities in
              the thriving industry of real estate development. <br />
            </Typography>
            <Typography variant="h5" component="div" sx={{ mt: 2, mb: 2 }}>
              ORGANIZED IN 2020
            </Typography>
            <Box sx={{ my: 4 }}>
              <img
                src={AcresNewsLatestB}
                alt="ACRES News A"
                width={lg ? 500 : 250}
              />
            </Box>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              Anthony Gerard Leuterio, president of “A Better Real Estate
              Philippines (Abrep) gave a positive outlook in the real estate
              business in the country, considering the backlog of 6.5 million
              homes in the Philippines. <br /> “The bounce back is coming in the
              next six months to one year. That is where ACRES will come in and
              really create more opportunities for people to become sales
              people.” He too believed that the big chunk of real estate
              investments would come from the OFWs who bring in at least $32
              billion every year.
            </Typography>

            <Typography
              variant="p"
              component="div"
              sx={{ mt: 2, mb: 2, color: "primary.main" }}
            >
              Conceived in 2020 and formally registered with the Securities and
              Exchange Commission (SEC) on February 15, 2021, ACRES is a
              collective body which envisioned itself to be the voice of the
              salespersons in the mission of enabling and empowering licensed
              practitioners to become highly ethical and knowledgeable
              professionals in their real estate practice.
            </Typography>
            <Typography variant="h5" component="div" sx={{ mt: 2, mb: 2 }}>
              BACKERS
            </Typography>
            <Box sx={{ my: 4 }}>
              <img
                src={AcresNewsLatestC}
                alt="ACRES News A"
                width={lg ? 500 : 250}
              />
            </Box>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              Anthony Gerard Leuterio, president of “A Better Real Estate
              Philippines (Abrep) said the formation of Acres came timely as the
              real estate sector experiences a rebound from the economic
              stresses brought about by the Covid-19 pandemic. He said unifying
              all the salespersons involved in real estate will further advance
              the country’s real estate sector and make it one of the strongest
              economic pillars amid the headwinds. Leuterio stressed that since
              real estate salespersons are significant contributors in the
              continued expansion of the sector, they must be well represented.
            </Typography>
            <Typography
              variant="p"
              component="div"
              sx={{ mt: 2, mb: 2, color: "primary.main" }}
            >
              ACRES is backed by influential organizations like its advisory
              group ABREP or “A Better Real Estate Philippines.” It also enjoys
              the support of the country’s established property developers.
            </Typography>
            <Typography variant="h5" component="div" sx={{ mt: 2, mb: 2 }}>
              GAME CHANGER
            </Typography>
            <Typography
              variant="p"
              component="div"
              sx={{ mt: 2, mb: 2, color: "primary.main" }}
            >
              ACRES is deemed as the game changer in the real estate
              salesperson’s profession, raising the bar and leveling the playing
              field in real estate selling as well as highlighting ethical
              practice and upholding professionalism.
            </Typography>
            <Typography
              variant="p"
              component="div"
              sx={{ mt: 2, mb: 2, color: "primary.main" }}
            >
              ACRES’ formation will also aid the country to craft better
              policies and legislations to further accelerate the growth of the
              industry. It also aims the much needed change on real estate laws
              that are now deemed irrelevant and untimely while representing the
              industry practitioners in pushing for better and all inclusive
              agenda that capture more opportunities for the sector.
            </Typography>
            <Typography variant="h5" component="div" sx={{ mt: 2, mb: 2 }}>
              WATCHDOG
            </Typography>
            <Typography
              variant="p"
              component="div"
              sx={{ mt: 2, mb: 2, color: "primary.main" }}
            >
              ACRES also serves as a watchdog against sale of anomalous
              properties, unscrupulous buyers, and persons that misrepresent
              projects or land holdings of dubious nature.
            </Typography>
            <Typography
              variant="p"
              component="div"
              sx={{ mt: 2, mb: 2, color: "primary.main" }}
            >
              Over the years the plight of the growing real estate sales persons
              in the Philippines has been downplayed, yet they thrived voiceless
              and endured difficult regulatory processes and absence of support
              for easier access to regulatory and licenses.
            </Typography>
            <Typography variant="h5" component="div" sx={{ mt: 2, mb: 2 }}>
              BIGGEST REAL ESTATE CONFAB
            </Typography>
            <Box sx={{ my: 4 }}>
              <img
                src={AcresNewsLatestE}
                alt="ACRES News A"
                width={lg ? 500 : 250}
              />
            </Box>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              ACRES chapter presidents from various parts of Mindanao join
              ACRES’ National President Chris Malazarte and Anthony Gerard
              Leuterio, president of “A Better Real Estate Philippines (Abrep)
              in answering queries from the Davao City media during the first
              and biggest gathering of real estate professionals in Mindanao
              held at the Apo View Hotel on May 2, 2023.
            </Typography>
            <Box sx={{ my: 4 }}>
              <img
                src={AcresNewsLatestA}
                alt="ACRES News A"
                width={lg ? 500 : 250}
              />
            </Box>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              “Personally, I would like to say that we are up for better times
              ahead because we are seeing a recovery already. The recovery has
              already started sometime in 2021. Despite what happened from 2021,
              the growth of the real estate did not really get into the dumps,
              we did not have any nose dive. Our outlook for the next two to
              three years is going to be very rosy,” ACRES’ National President
              Chris Malazarte said.
            </Typography>
            <Typography
              variant="p"
              component="div"
              sx={{ mt: 2, mb: 2, color: "primary.main" }}
            >
              ACRES Philippines recently gathered in a summit various real
              estate service professionals and government regulators to tackle
              several issues that beset the real estate service practice. The
              summit hosted by ACRES Davao chapter was also attended by
              delegates from different ACRES chapters from across Mindanao
              including real estate organizations from the different regions on
              May 2, 2023 at the Apo View Hotel, this city. Earlier, ACRES held
              its first national convention in Cebu City on August 25.
              (GAFlaviano)
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              Source :
              <Link href="https://southernerph.com/meet-the-champion-of-the-voiceless-ahente-ng-lupa/?fbclid=IwAR0pWANXTBpBwaIJu_UsnJ09r_8LElpcgv8BF_W0eMnxqhX-Qs0Jy3jEg3k">
                https://southernerph.com/meet-the-champion-of-the-voiceless-ahente-ng-lupa/?fbclid=IwAR0pWANXTBpBwaIJu_UsnJ09r_8LElpcgv8BF_W0eMnxqhX-Qs0Jy3jEg3k
              </Link>
            </Typography>
          </Grid>
          <Grid item lg={12} xs={12} md={12} sx={{ mt: 5, mb: 5 }}>
            <Typography
              variant="h4"
              component="div"
              sx={{ fontWeight: "bold" }}
            >
              "Very rosy" outlook for real estate industry bared in Cebu City
              confab
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2 }}>
              Calvin Cordova August 29, 2022
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              CEBU CITY - The next three years will be "very rosy" for those in
              the real estate industry.
            </Typography>
            <img src={AcresNewsA} alt="ACRES News A" width={lg ? 800 : 400} />
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              PRIME movers of the Real Estate Salespersons Congress gather for a
              photo prior to the start of the convention at Radisson Blu in Cebu
              City Thursday, Aug. 25. (Calvin D. Cordova) Players in the real
              estate industry shared this outlook during the first-ever Real
              Estate Salespersons Congress organized by the Accredited Real
              Estate Salespersons in the Philippines (ACRES Philippines)
              Thursday, Aug. 25, at Radisson Blu here.
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              Chris Malazarte, president of ACRES-Cebu South, said the industry
              was fortunate to have not been seriously affected by the Covid-19
              pandemic and the redeployment of Overseas Filipino Workers (OFWs)
              will further give the industry a boost. “Personally, I would like
              to say that we are up for better times ahead because we are seeing
              a recovery already. The recovery has already started sometime in
              2021 and I am very, very happy to say that despite what has
              happened from 2020, the growth of the real estate did not really
              get into the dumps, we did not have any nose dive or anything,”
              Malazarte said in a press briefing prior to the start of the
              convention. The convention was attended by some 700 members of
              ACRES and other Professional Regulation Commission-registered
              salespersons. Malazarte said OFWs are the biggest contributor to
              the rise of real estate industry as “they are always in the look,
              not only for residential housing, but also as well for
              investments.”
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              “We would like to take advantage of the market sentiment which is
              very positive. Our outlook for the next two to three years is
              going to be very rosy,” said Malazarte. Alejandro Mañalac,
              chairman and co-founder of the Manila-based Havitas Development
              Corp., said the reopening of the economy will give the industry a
              major boost. Mañalac said OFW deployment dropped by 75 percent in
              2020 but the remittances in December of that year only dropped by
              0.8 percent.
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              In 2021, OFW remittances increased by 5.9 percent, Mañalac shared.
              Anthony Gerard Leuterio, president of A Better Real Estate
              Philippines, also shared positive outlook in the real estate
              business in the country, citing a backlog of 6.5 million homes in
              the Philippines. “The bounce back is coming in the next six months
              to one year. That is where ACRES will come in and really create
              more opportunities for people to become sales people,” Leuterio
              said. Mañalac, Leuterio, and Malazarte welcomed the holding of the
              convention.
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              Malazarte said the convention was meant to find measures to make
              the profession more credible and give equal opportunity in terms
              of policy. “We are currently disjointed. There seems to be a lack
              of recognition,” Malazarte said. The group pushed for a review of
              the 14-year-old Real Estate Service Act or the Resa Law. Leuterio
              said real estate industry players should have a voice especially
              in policy-making. “Real estate is a sunshine industry but nobody
              is taking care of the industry,” said Leuterio.
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              Source :
              <Link href="https://mb.com.ph/2022/08/29/very-rosy-outlook-for-real-estate-industry-bared-in-cebu-city-confab/?amp&fbclid=IwAR0a-IyNLivmW1MLk1dpgO8eR6Yd-q_RIpJC96IpKw18gqOLtJovyXDpMRU">
                https://mb.com.ph/2022/08/29/very-rosy-outlook-for-real-estate-industry-bared-in-cebu-city-confab/?amp&fbclid=IwAR0a-IyNLivmW1MLk1dpgO8eR6Yd-q_RIpJC96IpKw18gqOLtJovyXDpMRU
              </Link>
            </Typography>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Typography
              variant="h4"
              component="div"
              sx={{ fontWeight: "bold" }}
            >
              Group to lobby PRC, DHSUD to streamline regulations for real
              estate salespersons
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2 }}>
              September 07, 2022
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              THE Accredited Real Estate Salespersons in the Philippines (ACRES)
              is set to submit a position paper to two government agencies
              urging them to come up with simple and easy regulations and
              requirements to help more Filipinos take advantage of the robust
              opportunities in real estate service.
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              ACRES interim national president Chris Malazarte said the group
              will submit a position paper to the Professional Regulation
              Commission (PRC) and Department of Human Settlements and Urban
              Development (DHSUD) to appeal for reforms in providing licenses
              and other regulatory processes that could promote speedy approval
              and fast releasing of registrations and accreditations.
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              ACRES, a group of over 700 real estate salespersons across the
              Philippines, claimed that with the current system, their
              profession is "overly regulated” with overlapping requirements
              from the DHSUD and PRC. While the real estate industry has
              hierarchy of positions practicing in various expertise, Malazarte
              said salespersons despite their significant contribution are often
              overlooked. He said the newly formed organization is set to craft
              advocacies to support the practitioners, as well as encourage
              entrepreneurship through this profession.
            </Typography>
            <Typography variant="h6" component="div" sx={{ mt: 2, mb: 2 }}>
              Policies, bills
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              Moreover, ACRES also expressed its willingness to work with
              lawmakers in crafting and updating some of the country’s real
              estate policies to maximize the industry’s full potential. It
              specifically urges lawmakers to look into updating the 13-year-old
              Real Estate Service Act (Resa) of 2009, or Resa Law. <br />
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              The Resa Law recognizes the vital role of real estate service
              practitioners in the social, political, economic development and
              progress of the country by promoting the real estate market,
              stimulating economic activity and enhancing government income.{" "}
              <br /> "We want to help our lawmakers make Resa Law responsive to
              the needs of the profession, needs of the developers and the
              industry as a whole,” said Malazarte. <br /> Malazarte, together
              with national officers of ACRES, believe there are parts of the
              Resa Law that are not applicable today, especially with the rapid
              progress of technology affecting the way of doing business.
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              The The group will also push for the creation of a Real Estate
              Commission, similar to the Insurance Commission for insurance
              agents. Malazarte said the industry must protect the salespersons
              since they are the life blood of the real estate industry who are
              bringing in hefty revenues and generating millions of jobs for the
              country. <br /> Real estate salespersons comprise the biggest
              number of professionals in the real estate service. Sixty
              industries are said to benefit from every property they sell.
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              Moreover, the group will also work with lawmakers to help draft a
              bill that zeroes in on the standardization of the real estate
              practice in the Philippines, among other concerns. Malazarte said
              they want to get rid of the proliferation of colorum practitioners
              to protect the stellar growth of the industry and to level the
              playing field among real estate service practitioners. ACRES is
              the country’s first and largest organization of accredited real
              estate salespersons.
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              ACRES is the country’s first and largest organization of
              accredited real estate salespersons.
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              Founded in 2021, it supports the endeavors of salespersons in
              furthering their learnings, ethical standards, protection of their
              rights to free and fair practice, upliftment of their well-being
              and due representation and inclusion on matters of their trade and
              prosperity.
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              At present the association has set up nationwide chapters with
              interim officers in Metro Manila, Makati, Mandaluyong, Camarines
              Sur, Cavite, Bicol, Sorsogon, Legaspi, Pampanga, Palawan,
              Tacloban, Ormoc, Bohol, Dumaguete, Bacolod, Kabankalan, Iloilo,
              Roxas, Aklan, Calbayog, Samar, Leyte, Cebu, Lapu-Lapu, Northern
              Cebu, Southern Cebu, Talisay, Mandaue, Catbalogan, Davao, General
              Santos, Caraga, Cagayan de Oro, Bukidnon, Surigao, Ozamis,
              Dipolog, Pagadian, Digos, Iligan and Gingoog.
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              Source :
              <Link href="https://www.sunstar.com.ph/ampArticle/1940162?fbclid=IwAR199xKHSHtyFhiD8QgtwjwGgfnujYnu7xh4tS_I9fsdrJHtEuFBg97Pc3c">
                https://www.sunstar.com.ph/ampArticle/1940162?fbclid=IwAR199xKHSHtyFhiD8QgtwjwGgfnujYnu7xh4tS_I9fsdrJHtEuFBg97Pc3c
              </Link>
            </Typography>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Typography
              variant="h4"
              component="div"
              sx={{ fontWeight: "bold", mb: 3 }}
            >
              Real estate congress kicks off in Cebu City
            </Typography>
            <img
              src={AcresNewsB}
              alt="Who we are image"
              width={lg ? 800 : 400}
            />
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              CEBU CITY — Over 700 real estate salespersons from the country
              will convene in Cebu City in a bid to professionalize their ranks
              and curb illegal sellers and dubious developers in the real estate
              sector.
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              The first Real Estate Salespersons Congress on Thursday, August
              25, at the Radisson Blu hotel will formally introduce their
              organization: Accredited Real Estate Salespersons in the
              Philippines (ACRES), a registered group by the Securities and
              Exchange Commission (SEC).
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              The convention will also tackle current concerns in real estate
              selling which is a booming industry, especially in Cebu.
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              Delegates in the convention include ACRES members from Metro
              Manila, Makati, Mandaluyong, Camarines Sur, Cavite, Bicol,
              Sorsogon, Legaspi, Pampanga, Palawan, Tacloban, Ormoc, Bohol,
              Dumaguete, Bacolod, Kabankalan, Iloilo, Roxas, Aklan, Calbayog,
              Samar, Leyte, Cebu, Lapu-Lapu, Northern Cebu, Southern Cebu,
              Talisay, Mandaue, Catbalogan, Davao, General Santos, Caraga,
              Cagayan de Oro, Bukidnon, Surigao, Ozamis, Dipolog, Pagadian,
              Digos, Iligan and Gingoog.
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              “Cebu was picked as the host for this year’s Congress to showcase
              the city’s progressive real estate industry whose vibrance could
              only be kept and achieved by proactive industry practitioners,
              including real estate salespersons,” the statement from ACRES
              read.
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              According to Anthony Gerard Leuterio, president of A Better Real
              Estate Philippines (ABREP), the advisory group of ACRES, the
              formation of ACRES came timely as the real estate sector
              experiences a rebound from the economic stresses brought about by
              COVID-19 pandemic.
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              Leuterio said unifying all the salespersons involved in real
              estate will further advance the country’s real estate sector and
              make it one of the strongest economic pillars amid the headwinds.
              “Since real estate is a selling business, we also need to get the
              salespersons onboard and actively involve them in advancing the
              sector alongside the brokers,” he said.
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              ACRES is the country’s first and largest organization of
              accredited real estate salespersons. Founded in 2021, it supports
              the endeavors of salespersons in furthering their learnings,
              ethical standards, protection of their rights to free and fair
              practice, upliftment of their well-being, and due representation
              and inclusion on matters of their trade and prosperity.
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              The group is also currently collaborating with the Department of
              Human Settlements and Urban Development (DHSUD) to participate on
              campaigns against illegal salespersons and illegal developers.
            </Typography>
            <Typography variant="p" component="div" sx={{ mt: 2, mb: 2 }}>
              Source :
              <Link href="https://newsinfo.inquirer.net/1652089/real-estate-congress-kicks-off-in-cebu-city/amp?fbclid=IwAR2xT8g6ElLYw0lK08ufAgrjKnvUmEXdq2f_jeY55fheWAdLpqgr2brcQ3s">
                https://newsinfo.inquirer.net/1652089/real-estate-congress-kicks-off-in-cebu-city/amp?fbclid=IwAR2xT8g6ElLYw0lK08ufAgrjKnvUmEXdq2f_jeY55fheWAdLpqgr2brcQ3s
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default News;
